// const apiServicePath = '/';
import { apiBaseUrl } from "./baseURL";

const mockApiBaseUrl = '/mock/'
export const urls = Object.freeze({

  signIn: `${apiBaseUrl}login`,
  authenticate: `${apiBaseUrl}authenticate`,
  
  employeeMaster: `${mockApiBaseUrl}employeeMaster`,
  saveEmployeeMaster: `${mockApiBaseUrl}saveEmployeeMaster`,
  deleteEmployeeMaster: (id) => `${mockApiBaseUrl}deleteEmployeeMaster/${id}`,
  deleteSelectedEmployees: `${mockApiBaseUrl}employee-master/delete`,

  areaMaster: `${apiBaseUrl}area-list`,
  cityListByArea: `${apiBaseUrl}area-by-city`,
  saveAreaMaster: `${apiBaseUrl}area-store`,
  updateAreaMaster: `${apiBaseUrl}area-update`,
  deleteAreaMaster: (id) => `${apiBaseUrl}area-destroy/${id}`,
  getEditAreaMaster: (id) => `${apiBaseUrl}area-show/${id}`,

  allowanceMaster: `${apiBaseUrl}daily-allowance-cost-list`,
  saveAllowanceMaster: `${apiBaseUrl}daily-allowance-cost-store`,
  updateAllowanceMaster: `${apiBaseUrl}daily-allowance-cost-update`,
  deleteAllowanceMaster: (id) => `${apiBaseUrl}daily-allowance-cost-destroy/${id}`,
  getEditAllowanceMaster: (id) => `${apiBaseUrl}daily-allowance-cost-show/${id}`,

  bankMaster: `${apiBaseUrl}bank-list`,
  saveBankMaster: `${apiBaseUrl}bank-store`,
  updateBankMaster: `${apiBaseUrl}bank-update`,
  deleteBankMaster: (id) => `${apiBaseUrl}bank-destroy/${id}`,
  getEditBankMaster: (id) => `${apiBaseUrl}bank-show/${id}`,

  brandMaster: `${apiBaseUrl}brand-list`,
  saveBrandMaster: `${apiBaseUrl}brand-store`,
  updateBrandMaster: `${apiBaseUrl}brand-update`,
  deleteBrandMaster: (id) => `${apiBaseUrl}brand-destroy/${id}`,
  getEditBrandMaster: (id) => `${apiBaseUrl}brand-show/${id}`,

  categoryMaster: `${apiBaseUrl}category-list`,
  saveCategoryMaster: `${apiBaseUrl}category-store`,
  updateCategoryMaster: `${apiBaseUrl}category-update`,
  deleteCategoryMaster: (id) => `${apiBaseUrl}category-destroy/${id}`,
  getEditCategoryMaster: (id) => `${apiBaseUrl}category-show/${id}`,

  cityMaster: `${apiBaseUrl}city-list`,
  cityListByDistrict: `${apiBaseUrl}city-by-district`,
  saveCityMaster: `${apiBaseUrl}city-store`,
  updateCityMaster: `${apiBaseUrl}city-update`,
  deleteCityMaster: (id) => `${apiBaseUrl}city-destroy/${id}`,
  getEditCityMaster: (id) => `${apiBaseUrl}city-show/${id}`,

  claimBackofficeList: `${apiBaseUrl}claim-backoffice-list`,
  getClaimBackofficeList: (id) => `${apiBaseUrl}claim-details/${id}`,
  approveClaimIfoods: `${apiBaseUrl}claim-approval`,
  rejectClaimIfoods: `${apiBaseUrl}claim-approval`,
  saveClaimMaster: `${apiBaseUrl}add-claim`,
  updateClaimMaster: `${apiBaseUrl}update-claim`,
  claimReport: `${apiBaseUrl}claim-report`,
  claimExpenseReport: `${apiBaseUrl}claim-expense-report`,

  companyMaster: `${apiBaseUrl}company-list`,
  saveCompanyMaster: `${apiBaseUrl}company-store`,
  updateCompanyMaster: `${apiBaseUrl}company-update`,
  deleteCompanyMaster: (id) => `${apiBaseUrl}company-destroy/${id}`,
  getEditCompanyMaster: (id) => `${apiBaseUrl}company-show/${id}`,

  competitorMaster: `${apiBaseUrl}competitor-list`,
  saveCompetitorMaster: `${apiBaseUrl}competitor-store`,
  updateCompetitorMaster: `${apiBaseUrl}competitor-update`,
  deleteCompetitorMaster: (id) => `${apiBaseUrl}competitor-destroy/${id}`,
  getEditCompetitorMaster: (id) => `${apiBaseUrl}competitor-show/${id}`,

  competitorOffers: `${apiBaseUrl}comp-prod-offers-list`,
  saveCompetitorOffers: `${apiBaseUrl}comp-prod-offers-store`,
  updateCompetitorOffers: `${apiBaseUrl}comp-prod-offers-update`,
  deleteCompetitorOffers: (id) => `${apiBaseUrl}comp-prod-offers-destroy/${id}`,
  getEditCompetitorOffers: (id) => `${apiBaseUrl}comp-prod-offers-show/${id}`,

  countryMaster: `${apiBaseUrl}country-list`,
  saveCountryMaster: `${apiBaseUrl}country-store`,
  updateCountryMaster: `${apiBaseUrl}country-update`,
  deleteCountryMaster: (id) => `${apiBaseUrl}country-destroy/${id}`,
  getEditCountryMaster: (id) => `${apiBaseUrl}country-show/${id}`,

  departmentMaster: `${apiBaseUrl}department-list`,
  departmentList: `${apiBaseUrl}department-by-division`,
  saveDepartmentMaster: `${apiBaseUrl}department-store`,
  updateDepartmentMaster: `${apiBaseUrl}department-update`,
  deleteDepartmentMaster: (id) => `${apiBaseUrl}department-destroy/${id}`,
  getEditDepartmentMaster: (id) => `${apiBaseUrl}department-show/${id}`,

  designationMaster: `${apiBaseUrl}designation-list`,
  designationList: `${apiBaseUrl}designation-by-department`,
  saveDesignationMaster: `${apiBaseUrl}designation-store`,
  updateDesignationMaster: `${apiBaseUrl}designation-update`,
  deleteDesignationMaster: (id) => `${apiBaseUrl}designation-destroy/${id}`,
  getEditDesignationMaster: (id) => `${apiBaseUrl}designation-show/${id}`,

  districtMaster: `${apiBaseUrl}district-list`,
  districtListByState: `${apiBaseUrl}district-by-state`,
  districtListBySubzone: `${apiBaseUrl}district-by-subzone`,
  saveDistrictMaster: `${apiBaseUrl}district-store`,
  updateDistrictMaster: `${apiBaseUrl}district-update`,
  getEditDistrictMaster: (id) => `${apiBaseUrl}district-show/${id}`,
  deleteDistrictMaster: (id) => `${apiBaseUrl}district-destroy/${id}`,

  divisionMaster: `${apiBaseUrl}division-list`,
  saveDivisionMaster: `${apiBaseUrl}division-store`,
  updateDivisionMaster: `${apiBaseUrl}division-update`,
  deleteDivisionMaster: (id) => `${apiBaseUrl}division-destroy/${id}`,
  getEditDivisionMaster: (id) => `${apiBaseUrl}division-show/${id}`,

  dropdownMaster: `${apiBaseUrl}dropdown-list`,
  saveDropdownMaster: `${apiBaseUrl}dropdown-store`,
  updateDropdownMaster: `${apiBaseUrl}dropdown-update`,
  deleteDropdownMaster: (id) => `${apiBaseUrl}dropdown-destroy/${id}`,
  getEditDropdownMaster: (id) => `${apiBaseUrl}dropdown-show/${id}`,
  dropdownListByDropdown: (id) => `${apiBaseUrl}dropdownlistsByDropdown/${id}`,

  dropdownlistMaster: `${apiBaseUrl}dropdownlist-list`,
  saveDropdownlistMaster: `${apiBaseUrl}dropdownlist-store`,
  updateDropdownlistMaster: `${apiBaseUrl}dropdownlist-update`,
  deleteDropdownlistMaster: (id) => `${apiBaseUrl}dropdownlist-destroy/${id}`,
  getEditDropdownlistMaster: (id) => `${apiBaseUrl}dropdownlist-show/${id}`,

  expenseCategory: `${apiBaseUrl}expense-category-ddlist`,
  expenseType: (id) => `${apiBaseUrl}expense-type-ddlist/${id}`,
  cityType: (cid, tid)  => `${apiBaseUrl}city-type-ddlist/${cid}/${tid}`,
  stayType: (cid, tid)  => `${apiBaseUrl}lodging-stay-type-ddlist/${cid}/${tid}`,

  energypriceMaster: `${apiBaseUrl}vh-energy-price-list`,
  saveEnergyPriceMaster: `${apiBaseUrl}vh-energy-price-store`,
  updateEnergyPriceMaster: `${apiBaseUrl}vh-energy-price-update`,
  deleteEnergyPriceMaster: (id) => `${apiBaseUrl}vh-energy-price-destroy/${id}`,
  getEditEnergyPriceMaster: (id) => `${apiBaseUrl}vh-energy-price-show/${id}`,

  energytypeMaster: `${apiBaseUrl}vh-energy-type-list`,
  saveEnergyTypeMaster: `${apiBaseUrl}vh-energy-type-store`,
  updateEnergyTypeMaster: `${apiBaseUrl}vh-energy-type-update`,
  deleteEnergyTypeMaster: (id) => `${apiBaseUrl}vh-energy-type-destroy/${id}`,
  getEditEnergyTypeMaster: (id) => `${apiBaseUrl}vh-energy-type-show/${id}`,

  accommodationMaster: `${apiBaseUrl}accommodation-cost-list`,
  saveAccommodationMaster: `${apiBaseUrl}accommodation-cost-update`,
  updateAccommodationMaster: `${apiBaseUrl}accommodation-cost-update`,
  deleteAccommodationMaster: (id) => `${apiBaseUrl}accommodation-cost-destroy/${id}`,
  getEditAccommodationMaster: (id) => `${apiBaseUrl}accommodation-cost-show/${id}`,

  gradeMaster: `${apiBaseUrl}grade-list`,
  gradeMasterOption: `${apiBaseUrl}grade-by-designation`,
  saveGradeMaster: `${apiBaseUrl}grade-store`,
  updateGradeMaster: `${apiBaseUrl}grade-update`,
  deleteGradeMaster: (id) => `${apiBaseUrl}grade-destroy/${id}`,
  getEditGradeMaster: (id) => `${apiBaseUrl}grade-show/${id}`,

  gradeList: `${apiBaseUrl}gradelist-list`,
  gradeListOption: `${apiBaseUrl}grade-list-by-grade`,
  saveGradeList: `${apiBaseUrl}gradelist-store`,
  updateGradeList: `${apiBaseUrl}gradelist-update`,
  deleteGradeList: (id) => `${apiBaseUrl}gradelist-destroy/${id}`,
  getEditGradeList: (id) => `${apiBaseUrl}gradelist-show/${id}`,

  materialMaster: `${apiBaseUrl}foods-material-list`,
  getMaterialDataFromSap: (id) => `${apiBaseUrl}sap-foods-material-creation/${id}`,
  saveMaterialMaster: `${apiBaseUrl}foods-material-store`,
  updateMaterialMaster: `${apiBaseUrl}foods-material-update`,
  deleteMaterialMaster: (id) => `${apiBaseUrl}foods-material-destroy/${id}`,
  getEditMaterialMaster: (id) => `${apiBaseUrl}foods-material-show/${id}`,

  menuMaster: `${apiBaseUrl}mainmenu-list`,
  saveMenuMaster: `${apiBaseUrl}mainmenu-store`,
  updateMenuMaster: `${apiBaseUrl}mainmenu-update`,
  deleteMenuMaster: (id) => `${apiBaseUrl}mainmenu-destroy/${id}`,
  getEditMenuMaster  : (id) => `${apiBaseUrl}mainmenu-show/${id}`,

  menuList: `${apiBaseUrl}menulist-list`,
  menuListMaster: `${apiBaseUrl}menulist-by-menu`,
  saveMenuListMaster: `${apiBaseUrl}menulist-store`,
  updateMenuListMaster: `${apiBaseUrl}menulist-update`,
  deleteMenuListMaster  : (id) => `${apiBaseUrl}menulist-destroy/${id}`,
  getEditMenuListMaster  : (id) => `${apiBaseUrl}menulist-show/${id}`,

  menuAccess: `${apiBaseUrl}menuaccess-list`,
  saveMenuAccess: `${apiBaseUrl}menuaccess-store`,
  updateMenuAccess: `${apiBaseUrl}menuaccess-update`,
  deleteMenuAccess : (id) => `${apiBaseUrl}menuaccess-destroy/${id}`,
  getEditMenuAccess  : (id) => `${apiBaseUrl}menuaccess-show/${id}`,

  outletMaster: `${apiBaseUrl}ifoodsoutlet-list`,
  outletBySubzone: `${apiBaseUrl}outlet-by-subzone`,
  saveOutletMaster: `${apiBaseUrl}ifoodsoutlet-store`,
  updateOutletMaster: `${apiBaseUrl}ifoodsoutlet-update`,
  deleteOutletMaster: (id) => `${apiBaseUrl}ifoodsoutlet-destroy/${id}`,
  getEditOutletMaster: (id) => `${apiBaseUrl}ifoodsoutlet-show/${id}`,

  plantMaster: `${apiBaseUrl}plant-list`,
  plantList: `${apiBaseUrl}plant-by-division`,
  savePlantMaster: `${apiBaseUrl}plant-store`, 
  updatePlantMaster: `${apiBaseUrl}plant-update`, 
  deletePlantMaster: (id) => `${apiBaseUrl}plant-destroy/${id}`,
  getEditPlantMaster: (id) => `${apiBaseUrl}plant-show/${id}`,

  productMaster: `${apiBaseUrl}product-list`,
  saveProductMaster: `${apiBaseUrl}product-store`,
  updateProductMaster: `${apiBaseUrl}product-update`,
  deleteProductMaster: (id) => `${apiBaseUrl}product-destroy/${id}`,
  getEditProductMaster: (id) => `${apiBaseUrl}product-show/${id}`,

  prospectMaster: `${apiBaseUrl}ifoods-prospect-list`,
  saveProspectMaster: `${apiBaseUrl}ifoods-prospect-store`,
  prospectApproval: `${apiBaseUrl}ifoods-prospect-validation-approval`,
  updateProspectMaster: `${apiBaseUrl}ifoods-prospect-update`,
  pendingProspectMaster: `${apiBaseUrl}prospect-back-office-list`,
  deleteProspectMaster: (id) => `${apiBaseUrl}ifoods-prospect-destroy/${id}`,
  getEditProspectMaster: (id) => `${apiBaseUrl}ifoods-prospect-show/${id}`,
  prospectReport: `${apiBaseUrl}ifoods-prospect-report`,


  prospectValidation: `${apiBaseUrl}ifoods-prospect-list`,
  getEditProspectApprove: (id) => `${apiBaseUrl}ifoods-prospect-show/${id}`,

  stateMaster: `${apiBaseUrl}state-list`,
  stateList: `${apiBaseUrl}state-by-zone`,
  saveStateMaster: `${apiBaseUrl}state-store`,
  updateStateMaster: `${apiBaseUrl}state-update`,
  deleteStateMaster: (id) => `${apiBaseUrl}state-destroy/${id}`,
  getEditStateMaster: (id) => `${apiBaseUrl}state-show/${id}`,

  shiftMaster: `${apiBaseUrl}shift-list`,
  saveShiftMaster: `${apiBaseUrl}shift-store`,
  updateShiftMaster: `${apiBaseUrl}shift-update`,
  deleteShiftMaster: (id) => `${apiBaseUrl}shift-destroy/${id}`,
  getEditShiftMaster: (id) => `${apiBaseUrl}shift-show/${id}`,
  
  roleMaster: `${apiBaseUrl}role-list`,
  saveRoleMaster: `${apiBaseUrl}role-store`,
  updateRoleMaster: `${apiBaseUrl}role-update`,
  deleteRoleMaster: (id) => `${apiBaseUrl}role-destroy/${id}`,
  getEditRoleMaster  : (id) => `${apiBaseUrl}role-show/${id}`,

  zoneMaster: `${apiBaseUrl}zone-list`,
  accessibleZoneMaster: `${apiBaseUrl}accessible-zones`,
  saveZoneMaster: `${apiBaseUrl}zone-store`,
  updateZoneMaster: `${apiBaseUrl}zone-update`,
  deleteZoneMaster: (id) => `${apiBaseUrl}zone-destroy/${id}`,
  getEditZoneMaster : (id) => `${apiBaseUrl}zone-show/${id}`,

  subzoneMaster: `${apiBaseUrl}sub-zone-list`,
  subZoneList: `${apiBaseUrl}sub-zone-by-zone`,
  saveSubzoneMaster: `${apiBaseUrl}sub-zone-store`,
  updateSubzoneMaster: `${apiBaseUrl}sub-zone-update`,
  deleteSubzoneMaster: (id) => `${apiBaseUrl}sub-zone-destroy/${id}`,
  getEditSubzoneMaster : (id) => `${apiBaseUrl}sub-zone-show/${id}`,

  venueMaster: `${apiBaseUrl}venuemapping-list`,
  saveVenueMaster: `${apiBaseUrl}sub-zone-store`,
  updateVenueMaster: `${apiBaseUrl}venuemapping-update`,
  deleteVenueMaster: (id) => `${apiBaseUrl}sub-zone-destroy/${id}`,
  getEditVenueMaster : (id) => `${apiBaseUrl}venuemapping-show/${id}`,

  pjpStore: `${apiBaseUrl}foods-pjp-details-store`,
  pjpMaster: `${apiBaseUrl}foods-pjp-details`,
  pjpUpdate: `${apiBaseUrl}foods-pjp-details-update`,
  pjpDelete: (id) => `${apiBaseUrl}foods-pjp-details-destroy/${id}`,
  getEditPjpMaster: (id) => `${apiBaseUrl}foods-pjp-details-show/${id}`,

  primaryCustomer: `${apiBaseUrl}foods-primarycustomer-list`,
  getPrimeCustDataFromSap: (id) => `${apiBaseUrl}sap-foods-primary-customer/${id}`,
  primeCustListBySubzone: `${apiBaseUrl}primarycustomer-by-subzone`,
  savePrimaryCustomerMaster: `${apiBaseUrl}foods-primarycustomer-store`,
  updatePrimaryCustomerMaster: `${apiBaseUrl}foods-primarycustomer-update`,
  deletePrimaryCustomerMaster: (id) => `${apiBaseUrl}foods-primarycustomer-destroy/${id}`,
  getEditPrimaryCustomerMaster : (id) => `${apiBaseUrl}foods-primarycustomer-show/${id}`,
  primaryCustomerReport: `${apiBaseUrl}primarycustomer-report`,

  primaryCustomerVisitReport: `${apiBaseUrl}customer-visit-report`,
  secondaryCustomerVisitReport: `${apiBaseUrl}secondary-customer-visit-report`,
  secondaryOrderReport: `${apiBaseUrl}secondary-orders-abstract`,

  secondaryCustomerMaster: `${apiBaseUrl}foods-secondarycustomer-list`,
  saveSecCustomerMaster: `${apiBaseUrl}foods-secondarycustomer-store`,
  updateSecCustomerMaster: `${apiBaseUrl}foods-secondarycustomer-update`,
  deleteSecondaryCustomerMaster: (id) => `${apiBaseUrl}foods-secondarycustomer-destroy/${id}`,
  getEditSecCustomerMaster : (id) => `${apiBaseUrl}foods-secondarycustomer-show/${id}`,
  secondaryCustomerReport: `${apiBaseUrl}secondarycustomer-report`,

  userMaster: `${apiBaseUrl}user-list`,
  getVendorCode: `${apiBaseUrl}user-vendor-code-list`,
  crewMembers: `${apiBaseUrl}crew-member-user`,
  saveUserMaster: `${apiBaseUrl}register`,
  updateUserMaster: `${apiBaseUrl}user-update`,
  deleteUserMaster: (id) => `${apiBaseUrl}user-destroy/${id}`,
  getEditUserMaster: (id) => `${apiBaseUrl}user-show/${id}`,
  getEditUserByDivision:  `${apiBaseUrl}division-by-user`,
  getUserByMapDivision:  `${apiBaseUrl}user-mapping-division`,

  saveUserImage: `${apiBaseUrl}file-upload`,
  deleteUserImage: `${apiBaseUrl}file-remove`,

  ifoodsPlanApproval: `${apiBaseUrl}plan-back-office-list`,
  getEditBoPlanApprove: (id) => `${apiBaseUrl}plan-details/${id}`,
  approvePlan: `${apiBaseUrl}approve-back-office`,
  rejectPlan: `${apiBaseUrl}reject-back-office`,

  ifoodsPlanDevData: `${apiBaseUrl}plan-deviation-back-office-list`,
  getEditBoPlanDevApprove: (id) => `${apiBaseUrl}back-office-deviation-list/${id}`,
  ifoodsPlanDevApprove: `${apiBaseUrl}deviation-approval-back-office`,
  ifoodsPlanDevReject: `${apiBaseUrl}deviation-rejected-back-office`,
  
  planApplicable: `${apiBaseUrl}planappgroup-list`,
  savePlanApplicable: `${apiBaseUrl}planappgroup-update`,
  updatePlanApplicable: `${apiBaseUrl}planappgroup-update`,
  deletePlanApplicable: (id) => `${apiBaseUrl}planappgroup-destroy/${id}`,
  getEditPlanApplicable : (id) => `${apiBaseUrl}planappgroup-show/${id}`,

  attendanceReport: `${apiBaseUrl}attendancepunch-report`,
  travelReport: `${apiBaseUrl}app-track-logs-report`,
  travelClaimReport: `${apiBaseUrl}get-travel-report`,
  // planReport: `${apiBaseUrl}plan-schedules-report`,
  planDetailsReport: `${apiBaseUrl}plan-details-log`,
  appTrackReport: `${apiBaseUrl}app-track-logs-report`,
  marketPromotionReport: `${apiBaseUrl}market-promotions-report`,
  outletVisitReport: `${apiBaseUrl}outlet-visit-report`,
  productOfferReport: `${apiBaseUrl}competitor-product-offers-report`,
  competitorReport: `${apiBaseUrl}competitor-report`,
  expApplicableAmount: `${apiBaseUrl}expense-applicable-amount`,
  getSharingUserList: `${apiBaseUrl}expense-twin-sharing-user-list`,
  getAllowTravelKm: `${apiBaseUrl}get-travelled-distance-from-geo`,
  appTransportMode: (id) => `${apiBaseUrl}transport-mode-ddlist/${id}`,

  glMaster: `${apiBaseUrl}glaccouts-list`,
  saveGlMaster: `${apiBaseUrl}glaccouts-store`,
  updateGlMaster: `${apiBaseUrl}glaccouts-update`,
  deleteGlMaster: (id) => `${apiBaseUrl}glaccouts-destroy/${id}`,
  getEditGlMaster: (id) => `${apiBaseUrl}glaccouts-show/${id}`,

  foodsCompetitorMaster: `${apiBaseUrl}foods-competitor-list`,
  saveFoodsCompetitorMaster: `${apiBaseUrl}foods-competitor-store`,
  updateFoodsCompetitorMaster: `${apiBaseUrl}foods-competitor-update`,
  deleteFoodsCompetitorMaster: (id) => `${apiBaseUrl}foods-competitor-destroy/${id}`,
  getEditFoodsCompetitorMaster: (id) => `${apiBaseUrl}foods-competitor-show/${id}`,
  

  
});

